import React, { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react';
import { CommonConstants, ProgressBarConstants, ScenePageConstants } from '@root/utils/constants';
import {
    CommonJobTypes,
    SharedJobTypes,
    ImageGenerationStatus,
    ProgressBarState,
    SceneMode,
    JobTypes
} from '@root/utils/constants/enums';
import { ErasePanoramaDto, GeneratePanoramaDto, SceneLayerDto, SceneSessionDto } from '@root/types/dto';
import { ScenePageContextItems } from '@root/types/contextTypes';
import { useSceneApi } from '@root/hooks/api/useSceneApi';
import { useJobs } from '@root/hooks/jobs/UseJobs';
import LocalStorageContext from '../LocalStorageContext/LocalStorageContext';
import { isResponseWithError } from '@root/utils/helpers';
import { JobWithAction } from '@root/utils/job/Job';
import { useLicenseValidation } from '../LicenseContext/useLicenseValidation';


const ScenePageContext =  createContext<ScenePageContextItems | null>(null);

export const ScenePageProvider = ({ children }: PropsWithChildren<unknown>): React.JSX.Element => {

    const { checkLicenseStatus } = useLicenseValidation();
    const { storageProjectId } = useContext(LocalStorageContext);
    const { updateJobStatus, enqueued, processing } = useJobs();
    const {
        createSceneSession,
        getSceneSessionByProjectId,
        renameSceneLayerById,
        getSceneSessionLayers,
        changeSceneLayersOrder,
        generatePanorama,
        deleteSceneLayerById,
        erasePanorama,
        generateHdr
    } = useSceneApi();

    //Tools states
    const [isToolbarDisabled, setIsToolbarDisabled] = useState<boolean>(false);
    const [isFullScreenActive, setIsFullScreenActive] = useState<boolean>(false);
    const [isDownloadDialogActive, setIsDownloadDialogActive] = useState<boolean>(false);
    const [activeMode, setActiveMode] = useState<SceneMode>(SceneMode.NONE);
    const [eraserSize, setEraserSize] = useState<number>(CommonConstants.DEFAULT_ERASER_SIZE);
    const [eraserMask, setEraserMask] = useState<Blob | null>(null);

    // Generation status states
    const [currentlyInProgress, setCurrentlyInProgress] = useState<number>(0);
    const [isEraserInProgress, setIsEraserInProgress] = useState<boolean>(false);
    const [statusUpdateRequired, setStatusUpdateRequired] = useState<boolean>(false);
    const [imageUpdateRequired, setImageUpdateRequired] = useState<boolean>(false);

    //Form states
    const [prompt, setPrompt] = useState<string>(ScenePageConstants.DEFAULT_PROMPT);
    const [negativePrompt, setNegativePrompt] = useState<string>(CommonConstants.NEGATIVE_PROMPT_PLACEHOLDER);
    const [seed, setSeed] = useState<string | number>(CommonConstants.DEFAULT_SEED_VALUE);
    const [enableHighResolution, setEnableHighResolution] = useState<boolean>(false);

    // Scene Images states
    const [activeSceneSession, setActiveSceneSession] = useState<SceneSessionDto | null>(null);
    const [imagesList, setImagesList] = useState<SceneLayerDto[]>([]);
    const [selectedImage, setSelectedImage] = useState<SceneLayerDto | null>(null);
    const [selectedImageUrl, setSelectedImageUrl] = useState<string>('');
    const [selectRequired, setSelectRequired] = useState<boolean>(true);

    // Progress Bar
    const [progressState, setProgressState] = useState<ProgressBarState>(ProgressBarState.Inactive);
    const [progressPercentage, setProgressPercentage] = useState<number>(0);

    const createSession = async (): Promise<void> => {
        if (!storageProjectId) {
            return;
        }

        const createdSession = await createSceneSession(storageProjectId);
    
        if (createdSession && !isResponseWithError(createdSession)) {
            setActiveSceneSession(createdSession);
            setImagesList([]);
        }
    };

    const openLastSession = async (): Promise<void> => {
        if (!activeSceneSession || activeSceneSession?.projectId !== storageProjectId) {
            const session = await getSceneSessionByProjectId(storageProjectId);

            if (!isResponseWithError(session) && session?.id) {
                setActiveSceneSession(session);
                getSceneSessionImagesList(session.id);
            } else {
                await createSession();
            }
        }
    };

    const updateGenerationStatuses = (): void => {
        if (progressState === ProgressBarState.Inactive ||
            progressState === ProgressBarState.Finished) {
            setProgressPercentage(ProgressBarConstants.QUEUED_MAX_VALUE);
            setProgressState(ProgressBarState.Queued);
        }
        updateJobStatus();
    };

    const getSceneSessionImagesList = async (sessionId: number): Promise<void> => {
        const sceneLayers = await getSceneSessionLayers(sessionId);

        if (isResponseWithError(sceneLayers) || !sceneLayers?.length) {
            return;
        }

        const sortedSceneLayers = sceneLayers
            .sort((a, b) => a.orderIndex - b.orderIndex)
            .filter((layer) => layer.statusPanoramaId === ImageGenerationStatus.READY);

        const currentGeneratedLayers = imagesList.filter(layer => layer.statusPanoramaId === ImageGenerationStatus.READY);

        if (sortedSceneLayers.length > currentGeneratedLayers.length) {
            setSelectedImageUrl('');
            setSelectRequired(true);

            if (isEraserInProgress) {
                setImageUpdateRequired(true);
            }
        }

        const layersWithHdr = sortedSceneLayers.filter((layer) => layer.statusHdrId === ImageGenerationStatus.READY);
        const currentLayersWithHdr = currentGeneratedLayers.filter(layer => layer.statusHdrId === ImageGenerationStatus.READY);

        if (layersWithHdr.length > currentLayersWithHdr.length) {
            setSelectedImageUrl('');
            setStatusUpdateRequired(true);
        }

        setImagesList(sortedSceneLayers);
    };

    const changeImagesOrder = async (imagesId: number[]): Promise<void> => {
        if (!activeSceneSession) {
            return;
        }

        const orderedImagesList = [...imagesList]
            .filter((layer) => layer.statusPanoramaId === ImageGenerationStatus.READY)
            .sort((a, b) => imagesId.indexOf(a.id) - imagesId.indexOf(b.id))
            .map((currElement, index) => {
                currElement.orderIndex = index + 1;
                return currElement;
            });
        
        setImagesList(orderedImagesList);

        const response = await changeSceneLayersOrder(activeSceneSession.id, imagesId);

        if (response && typeof response !== 'number') {
            const sceneLayers = await getSceneSessionLayers(activeSceneSession.id);
            
            if (isResponseWithError(sceneLayers) || !sceneLayers?.length) {
                return;
            }

            setImagesList(sceneLayers.sort((a, b) => a.orderIndex - b.orderIndex)
                .filter((layer) => layer.statusPanoramaId === ImageGenerationStatus.READY));
        }
    };

    const deleteImage = async (imageId: number): Promise<void> => {
        const deletedImageId = await deleteSceneLayerById(imageId);

        if (deletedImageId && typeof deletedImageId === 'string') {
            if (imageId === selectedImage?.id) {
                setSelectRequired(true);
            }

            setImagesList((prev) => prev.filter((el) => el.id !== imageId));
        }
    };

    const renameImage = async (imageId: number, name: string): Promise<void> => {
        const updatedSceneLayer = await renameSceneLayerById(imageId, name);

        if (updatedSceneLayer && !isResponseWithError(updatedSceneLayer)) {
            const updatedImagesList = [
                ...imagesList.filter((el) => el.id !== imageId),
                updatedSceneLayer,
            ];

            setImagesList(updatedImagesList.sort((a, b) => a.orderIndex - b.orderIndex)
                .filter((layer) => layer.statusPanoramaId === ImageGenerationStatus.READY));
        }
    };

    const generatePanoramaImage = async (): Promise<void> => {
        const isValid = await checkLicenseStatus();

        if (!isValid || !activeSceneSession) {
            return;
        }

        const fullPrompt = getFullPrompt();

        const generationConfig: GeneratePanoramaDto = {
            sceneSessionId: activeSceneSession.id,
            prompt: fullPrompt,
            negativePrompt: negativePrompt === CommonConstants.NEGATIVE_PROMPT_PLACEHOLDER ?
                ScenePageConstants.DEFAULT_NEGATIVE_PROMPT : negativePrompt,
            seed: seed === CommonConstants.DEFAULT_SEED_VALUE ? -1 : +seed,
            highQuality: enableHighResolution
        };

        const response = await generatePanorama(generationConfig);

        if (response && typeof response === 'number') {
            updateGenerationStatuses();
        }
    };

    const generateErasePanorama = async (): Promise<void> => {
        const isValid = await checkLicenseStatus();

        if (!isValid || !activeSceneSession || !selectedImage || !eraserMask) {
            setIsEraserInProgress(false);
            setActiveMode(SceneMode.PAN);
            return;
        }

        const generationConfig: ErasePanoramaDto = {
            sceneLayerId: selectedImage?.id,
            mask: eraserMask

        };

        const response = await erasePanorama(generationConfig);

        if (response && typeof response === 'number') {
            updateGenerationStatuses();
        }
    };

    const generateHdrImage = async (): Promise<void> => {
        const isValid = await checkLicenseStatus();

        if (!isValid || !selectedImage) {
            return;
        }

        const generationConfig = {
            sceneLayerId: selectedImage?.id,
        };

        const response = await generateHdr(generationConfig);

        if (response && typeof response === 'number') {
            updateGenerationStatuses();
        }
    };

    const toggleFullScreen = (): void => {
        setIsFullScreenActive(!isFullScreenActive);
    };

    const findCurrentImageIndex = (): number => {
        return imagesList.findIndex((image) => image.id === selectedImage?.id);
    };

    const moveToNextImage = (): void  => {
        const selectedImageIndex = findCurrentImageIndex();

        if (selectedImageIndex === 0 || !imagesList.length) {
            return;
        } else {
            setSelectedImage(imagesList[selectedImageIndex - 1]);
            setSelectedImageUrl(imagesList[selectedImageIndex - 1].filePanoramaPath);
        }
    };
    

    const moveToPreviousImage = (): void  => {
        const selectedImageIndex = findCurrentImageIndex();

        if (!imagesList.length || selectedImageIndex === imagesList.length - 1) {
            return;
        } else {
            setSelectedImage(imagesList[selectedImageIndex + 1]);
            setSelectedImageUrl(imagesList[selectedImageIndex + 1].filePanoramaPath);
        }
    };

    const getSceneJobs = (jobList: JobWithAction[]): JobWithAction[] => {
        const sceneJobs = jobList.filter(job =>
            job?.jobType === SharedJobTypes.ERASER ||
            job?.jobType === CommonJobTypes.PANORAMA ||
            job?.jobType === CommonJobTypes.HDR
        );

        return sceneJobs;
    };

    const cancelSceneJobGeneration = (imageId: number, jobType: JobTypes): void => {
        const filteredJobs = [...enqueued, ...processing].filter(job => job?.jobType === jobType);

        const selectedJob = filteredJobs.find((job) => {
            // job name is built according to the following template:
            // jobType + " " + id
            const match = job.name.match('\\s(\\d+)$');

            if (match && +match[1] === imageId) {
                return job;
            }
        });

        if (selectedJob?.cancel) {
            selectedJob.cancel();
        }
    };

    const getFullPrompt = (): string => {
        let promptStart = prompt.trim();
    
        if (promptStart.endsWith(',') || promptStart.endsWith('.')) {
            promptStart = promptStart.slice(0, -1);
        }
    
        if (!promptStart.endsWith(',')) {
            promptStart += ',';
        }
    
        return `${promptStart}${ScenePageConstants.PROMPT_END}`;
    };

    useEffect(() => {
        if (!eraserMask) {
            return;
        }

        setIsEraserInProgress(true);
        generateErasePanorama();
    }, [eraserMask]);


    useEffect(() => {
        setIsToolbarDisabled(isEraserInProgress);
    }, [isEraserInProgress]);

    useEffect(() => {

        const enqueuedScene = getSceneJobs(enqueued);

        const processingScene = getSceneJobs(processing);

        const eraserJobs = [...enqueuedScene, ...processingScene].filter(job => job?.jobType === SharedJobTypes.ERASER);
        setIsEraserInProgress(activeMode === SceneMode.ERASE && !!eraserJobs.length);

        const hdrJobs = [...enqueuedScene, ...processingScene].filter(job => job?.jobType === CommonJobTypes.HDR);
        setStatusUpdateRequired(!!hdrJobs.length);

        if (processingScene.length) {
            const progressValue = processingScene[0].percentage && processingScene[0].percentage > ProgressBarConstants.QUEUED_MAX_VALUE
            ? processingScene[0].percentage
            : ProgressBarConstants.QUEUED_MAX_VALUE;
    
            setProgressPercentage(progressValue);

            if (progressState === ProgressBarState.Queued ||
                progressState === ProgressBarState.Paused ||
                progressState === ProgressBarState.Inactive) {
                    setProgressState(ProgressBarState.InProgress);
            }
        }

        if (enqueuedScene.length || processingScene.length) {
            setCurrentlyInProgress(enqueuedScene.length + processingScene.length);
        } else {
            setCurrentlyInProgress(0);
            if (progressState !== ProgressBarState.Inactive) {
                setProgressPercentage(ProgressBarConstants.MAX_VALUE);
                setProgressState(ProgressBarState.Finished);
            }
        } 

        if (activeSceneSession?.id) {
            getSceneSessionImagesList(activeSceneSession.id);
        }
    }, [enqueued, processing]);


    useEffect(() => {
        if (currentlyInProgress > 0 && progressState === ProgressBarState.Waiting) {
            setProgressState(ProgressBarState.InProgress);

        } else if (currentlyInProgress === 0 && progressState !== ProgressBarState.Inactive) {
           setProgressState(ProgressBarState.Finished);
        }

    }, [currentlyInProgress]);
    
    useEffect(() => {
        if (!imagesList.length) {
            setIsToolbarDisabled(true);
            if (!isEraserInProgress) {
                setSelectedImage(null);
                setActiveMode(SceneMode.NONE);
                setSelectedImageUrl('');
            }
            return;
        } else {
            if (selectedImage && statusUpdateRequired) {
                setStatusUpdateRequired(false);
                const updatedSelectedImage = imagesList.find((el: SceneLayerDto) => el.id === selectedImage.id);
                if (updatedSelectedImage) {
                    setSelectedImage(updatedSelectedImage);
                }
            }

            if (selectRequired) {
                setSelectRequired(false);
                setIsToolbarDisabled(false);
                setSelectedImage(null);

                let newSelectedImage = null;
                if (imageUpdateRequired) {
                    newSelectedImage = imagesList.find((el: SceneLayerDto) => el.id === selectedImage?.id);
                    setImageUpdateRequired(false);
                } else {
                    newSelectedImage = (imagesList as any).findLast((el: SceneLayerDto) => el.filePanoramaPath &&
                    el.statusPanoramaId === ImageGenerationStatus.READY);
                }
                    
                if (newSelectedImage) {
                    setActiveMode(SceneMode.PAN);
                    setSelectedImage(newSelectedImage);
                    setSelectedImageUrl(newSelectedImage.filePanoramaPath);
                }
            }
        }
    }, [imagesList]);

    const value: ScenePageContextItems = {

        prompt,
        setPrompt,

        negativePrompt,
        setNegativePrompt,

        seed,
        setSeed,

        enableHighResolution,
        setEnableHighResolution,

        setActiveMode,
        activeMode,
        
        selectedImage,
        setSelectedImage,

        selectedImageUrl,
        setSelectedImageUrl,

        isDownloadDialogActive,
        setIsDownloadDialogActive,

        progressState,
        setProgressState,

        eraserSize,
        setEraserSize,

        isToolbarDisabled,
        setIsToolbarDisabled,

        isFullScreenActive,
        imagesList,
        currentlyInProgress,
        isEraserInProgress,
        progressPercentage,
    
        setEraserMask,
        changeImagesOrder,
        toggleFullScreen,
        moveToNextImage,
        moveToPreviousImage,
        openLastSession,
        generatePanoramaImage,
        deleteImage,
        renameImage,
        generateErasePanorama,
        generateHdrImage,
        cancelSceneJobGeneration
    };

    return (
        <ScenePageContext.Provider value={value}>
            {children}
        </ScenePageContext.Provider>
    );
};

export const useScene = (): ScenePageContextItems => {
    const context = useContext(ScenePageContext);

    if (context === null) {
        throw new Error('useScene cannot be used without its provider');
    }

    return context;
};