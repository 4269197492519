import React, {useEffect, useState } from 'react';
import { Box, IconButton, Stack, TextField, styled } from '@mui/material';
import { prefixBaseUrl } from '@root/utils/url/url';
import { useDrag } from '@root/context/DragContext/useDrag';
import { DeleteImageIcon } from '@root/assets/icons/DeleteImageIcon';
import { LayerPreviewProps } from './LayerPreview.types';
import { CurateToolsNames } from '@root/utils/constants/curateToolsNames';
import ListRowDragButton from '@root/components/SortableListHelpers/ListRowDragButton';

const LayerPreview = ({
    layer,
    orderIndex,
    isSelected,
    isCanvasLoad,
    setFocus,
    renameLayer,
    selectLayerByIndex,
    deleteLayer,
    showMetadataDialog,
    getBlendedImage
} : LayerPreviewProps): React.JSX.Element => {

    const { setDraggedImage } = useDrag();
    const [path, setPath] = useState<string>('');
    const [customName, setCustomName] = useState<string>('');
    const [initialName, setInitialName] = useState<string>('');

    const openMetadataDialog = (event: React.MouseEvent<HTMLImageElement>): void => {
        if (event.detail === 2) {
            showMetadataDialog();
        }
    };    

    const selectLayer = (): void => {
        if (!isCanvasLoad) {
            return;
        }
        selectLayerByIndex(layer.orderIndex);
        setFocus(true);
    };

    const deleteByClick = (event: React.MouseEvent): void => {
        event.preventDefault();
        event.stopPropagation();

        deleteLayer(layer.id);
    };
    
    const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setCustomName(event.target.value);
    };

    const blurHandler = (): void => {
        if (customName !== initialName) {
            renameLayer(layer.id, customName);
        }
    };

    const onDragStart = async(): Promise<void> => {
        if (!layer.imagePath) {
            return;
        }
        const filePath = prefixBaseUrl(layer.imagePath);

        if (layer.brashSketchImage) {
            const file = await getBlendedImage(filePath, prefixBaseUrl(layer.brashSketchImage));
            setDraggedImage(file);

        } else {

        fetch(filePath)
            .then((res) => res.blob())
            .then((blob) => {
                const file = new File([blob], 'image.png');
                setDraggedImage(file);
            });
        }
    };

    const onDragEnd = (): void => {
        setDraggedImage(null);
    };

    const handleKeyDown = (event: React.KeyboardEvent): void => {
        if(event.key === 'Enter') {
            (event.target as HTMLElement).blur(); 
        }
    };

    const updateImageName = (): void => {
        let layerName = `image ${layer.orderIndex}`;

        if (layer.name) {
            layerName = layer.name;
        } else if (layer.toolId) {
            layerName = `${CurateToolsNames[layer.toolId]} ${layer.orderIndex}`;
        }

        setInitialName(layerName);
        setCustomName(layerName);
    };

    useEffect(() => {
        updateImageName();
    }, []);

    useEffect(() => {
        const path = prefixBaseUrl(layer.previewPath ? layer.previewPath : layer.imagePath);

        setPath(path);
    }, [layer]);

    useEffect(() => {
        if (orderIndex) {
            updateImageName();
        }
    }, [orderIndex]);

    return (
        <Wrapper
            onClick={selectLayer}
            selected={isSelected}>
            <ListRowDragButton />
            <StyledImage
                loading="lazy"
                onClick={openMetadataDialog}
                onDragStart={onDragStart}
                onDragEnd={onDragEnd}
                src={path}
                active={isCanvasLoad ? 0 : 1}
            />
            <LayerInfo selected={isSelected}>
                <CustomInput
                    id={layer.orderIndex.toString()}
                    multiline={false}
                    variant="outlined"
                    onChange={onChangeHandler}
                    value={customName}
                    onBlur={blurHandler}
                    onKeyDown={handleKeyDown}
                />
                <Stack direction="row">
                    <IconButtonCss onClick={deleteByClick}>
                        <DeleteImageIcon/>
                    </IconButtonCss>
                </Stack>
            </LayerInfo>
        </Wrapper>
    );
};

export default React.memo(LayerPreview);

const Wrapper = styled(Box)<{ selected: boolean }>(({ theme, selected }) => ({
    direction: 'ltr',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: '64px',

    padding: '7px 29px 7px 7px',
    border: '1px solid',

    background: selected ? theme.palette.primary.light : theme.palette.neutrals.main,
    boxShadow: '2px 2px 10px 0px rgba(0, 0, 0, 0.3)',
    borderColor: 'transparent',

    '&:hover button': {
        opacity: 1,
    }
}));


const CustomInput = styled(TextField)(({ theme }) => ({
    '& input': {
        padding: '0',
        paddingLeft: '5px',
        color: theme.palette.neutrals.inactive1,
    }
}));

const LayerInfo = styled(Box)<{ selected: boolean }>(({ theme,  selected}) => ({
    display: 'flex',
    flex: '1',
    justifyContent: 'center',
    alignItems: 'center',

    border: '1px solid',
    borderRadius: '4px',
    borderColor: 'transparent',

    marginLeft: '10px',
    height: '25px',

    '& .MuiOutlinedInput-root': {
        minHeight: '25px',

        fontFamily: 'Roboto400',
        fontSize: '14px',
        lineHeight: '16px',

        '@media (max-width:1500px)': {
            fontSize: '10px',
            lineHeight: '12px',
        },

        padding: '0',

        'fieldset': {
            borderColor: 'transparent',
        },

        '&:hover fieldset': {
            borderColor: selected ? theme.palette.neutrals.main : theme.palette.primary.light,
        },

        '&.Mui-focused fieldset': {
            borderColor: theme.palette.primary.light1,
            borderWidth: '1px',
        },

        '&.Mui-focused input': {
            color: theme.palette.primary.contrastText,
        }
    },
}));

const StyledImage = styled('img')<{ active: number }>(({ active }) => ({
    width: '50px',
    height: '50px',

    objectFit: 'cover',
    cursor: 'pointer',

    opacity: active ? 0.8 : 1,
    borderRadius: '3px',
}));

const IconButtonCss = styled(IconButton)(({ theme }) => ({
    width: '24px',
    height: '24px',

    opacity: 0,
    color: theme.palette.neutrals.inactive1,

    '& svg': {
        fontSize: '16px',
    },

    '&:hover': {
        background: 'transparent',
    },
}));
